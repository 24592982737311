<template>
  <div class="shop-order">
    <el-card class="top-card">
      <el-button
        :type="tabIndex == i ? 'primary' : 'default'"
        v-for="(item, i) in tabList"
        :key="i"
        @click="changeTab(i)"
        >{{ item }}</el-button
      >
    </el-card>
    <el-card class="main-card table-page">
      <div class="search-form">
        <el-form :model="form" inline>
          <el-form-item :label="$t('ding-dan-bian-hao')" prop="orderNo">
            <el-input
              v-model="form.orderNo"
              :placeholder="$t('qing-shu-ru-ding-dan-bian-hao')"
            ></el-input>
          </el-form-item>

          <el-form-item :label="$t('xia-dan-shi-jian')" prop="orderTime">
            <el-date-picker
              v-model="form.orderTime"
              type="daterange"
              :start-placeholder="$t('kai-shi-ri-qi')"
              :end-placeholder="$t('jie-shu-ri-qi')"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search">{{
              $t('cha-xun')
            }}</el-button>
            <el-button @click="reset">{{ $t('zhong-zhi') }}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="main-table">
        <div class="table-btns">
          <el-button type="primary" @click="batchPay">{{
            $t('pi-liang-cai-gou')
          }}</el-button>
        </div>
        <div class="table-container">
          <el-table
            :data="tableData"
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              align="center"
              type="selection"
              width="55"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('ding-dan-bian-hao-0')"
              prop="OrderNo"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('shou-huo-ren-xing-ming')"
              prop="orderNumber"
            >
              <template slot-scope="scope">
                {{ scope.row.ShippingAddress.FullName }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :label="$t('shang-pin-jin-e')"
              prop="TotalAmount"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('li-run-0')"
              prop="Income"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('zhi-fu-zhuang-tai-0')"
              prop="Status"
            >
              <template slot-scope="scope">
                <div>{{ getStatusName(scope.row.Status) }}</div>
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              :label="$t('xia-dan-shi-jian-0')"
              prop="CreatedAt"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('ding-dan-chao-shi')"
              prop="orderNumber"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('cao-zuo')"
              width="200px"
            >
              <template slot-scope="scope">
                <div>
                  <el-button
                    size="mini"
                    type="text"
                    @click="orderDetail(scope.row)"
                    >{{ $t('cha-kan-ding-dan') }}</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    @click="orderLogistics(scope.row)"
                    v-if="
                      scope.row.Status != 0 &&
                      scope.row.Status != 1 &&
                      scope.row.Status != 5
                    "
                    >{{ $t('wu-liu-xin-xi') }}</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="bottom-page flex-center">
        <el-pagination
          background
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="page.current"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog
      :title="$t('ding-dan-bian-hao-detaildataorderno', [detailData.OrderNo])"
      :visible.sync="showOrderDialog"
      width="800px"
      class="order-detail-dialog"
    >
      <div class="top-info">
        <div class="title">{{ $t('ding-dan-zhai-yao') }}</div>
        <div class="order-info">
          <div class="info">
            <div class="label">{{ $t('ding-dan-hao-0') }}</div>
            <div class="value">{{ detailData.OrderNo }}</div>
          </div>
          <div class="info">
            <div class="label">{{ $t('xia-dan-shi-jian-1') }}</div>
            <div class="value">{{ detailData.CreatedAt }}</div>
          </div>
          <div class="info">
            <div class="label">{{ $t('fu-kuan-fang-shi') }}</div>
            <div class="value">{{ $t('qian-bao') }}</div>
          </div>
          <div class="info">
            <div class="label">{{ $t('zhi-fu-zhuang-tai-1') }}</div>
            <div class="value">{{ getStatusName(detailData.Status) }}</div>
          </div>
          <div class="info">
            <div class="label">{{ $t('cai-gou-zhuang-tai-0') }}</div>
            <div class="value">{{ detailData.orderNumber }}</div>
          </div>
          <div class="info">
            <div class="label">{{ $t('wu-liu-zhuang-tai-1') }}</div>
            <div class="value">{{ detailData.orderNumber }}</div>
          </div>
          <div class="info">
            <div class="label">{{ $t('cai-gou-jin-e') }}</div>
            <div class="value">${{ detailData.PickupAmount }}</div>
          </div>
          <div class="info">
            <div class="label">{{ $t('xiao-shou-jin-e') }}</div>
            <div class="value">${{ detailData.TotalAmount }}</div>
          </div>
          <div class="info">
            <div class="label">{{ $t('cai-gou-shi-jian') }}</div>
            <div class="value">{{ detailData.PickupTime }}</div>
          </div>
          <div class="info">
            <div class="label">{{ $t('li-run-0') }}</div>
            <div class="value">${{ detailData.Income }}</div>
          </div>
        </div>

        <div class="logistics-info order-info">
          <div class="info">
            <div class="label">{{ $t('xing-ming-0') }}</div>
            <div class="value">{{ detailData.ShippingAddress.FullName }}</div>
          </div>
          <div class="info">
            <div class="label">{{ $t('di-zhi-0') }}</div>
            <div class="value">{{ detailData.ShippingAddress.Address }}</div>
          </div>
          <div class="info">
            <div class="label">{{ $t('dian-hua') }}</div>
            <div class="value">{{ detailData.ShippingAddress.Phone }}</div>
          </div>
          <div class="info">
            <div class="label">{{ $t('guo-jia') }}</div>
            <div class="value">{{ detailData.ShippingAddress.Country }}</div>
          </div>
          <div class="info">
            <div class="label">{{ $t('sheng-qu') }}</div>
            <div class="value">{{ detailData.ShippingAddress.Province }}</div>
          </div>
          <div class="info">
            <div class="label">{{ $t('cheng-shi') }}</div>
            <div class="value">{{ detailData.ShippingAddress.City }}</div>
          </div>
          <div class="info">
            <div class="label">{{ $t('you-bian') }}</div>
            <div class="value">{{ detailData.ShippingAddress.ZipCode }}</div>
          </div>
        </div>
      </div>

      <div class="flex-start bottom-info">
        <div class="left">
          <div class="title">{{ $t('ding-dan-xiang-qing') }}</div>
          <el-table :data="detailData.OrderProduct" border size="mini">
            <el-table-column
              align="center"
              width="120px"
              :label="$t('cha-kan-tu-pian')"
              prop="orderNumber"
            >
              <template slot-scope="scope">
                <el-image
                  :src="scope.row.BusinessProduct.MainImage"
                  style="height: 50px"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="120px"
              :label="$t('chan-pin-bian-hao')"
              prop="BusinessProductId"
            ></el-table-column>
            <el-table-column
              align="center"
              width="230px"
              :label="$t('chan-pin-ming-cheng')"
              prop="orderNumber"
            >
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.BusinessProduct.ProductTranslation
                      ? scope.row.BusinessProduct.ProductTranslation[0].Name
                      : ''
                  }}
                </div>
              </template></el-table-column
            >
            <el-table-column
              align="center"
              :label="$t('gui-ge-0')"
              prop="OptionName"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('shu-liang')"
              prop="Number"
            ></el-table-column>
            <el-table-column
              align="center"
              width="120px"
              :label="$t('xiao-shou-jia-ge')"
              prop="Price"
            ></el-table-column>
            <el-table-column
              align="center"
              width="120px"
              :label="$t('cai-gou-jia-ge')"
              prop="PickupAmount"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('li-run-0')"
              prop="Income"
            ></el-table-column>
          </el-table>
        </div>
        <div class="right">
          <div class="title">{{ $t('ding-dan-jin-e') }}</div>
          <div class="money-info">
            <div class="info">
              <div class="label">{{ $t('xiao-ji') }}</div>
              <div class="value">${{ detailData.TotalAmount }}</div>
            </div>
            <div class="info">
              <div class="label">{{ $t('shui') }}</div>
              <div class="value">$0.00</div>
            </div>
            <div class="info">
              <div class="label">{{ $t('yun-fei') }}</div>
              <div class="value">$0.00</div>
            </div>
            <div class="info">
              <div class="label">{{ $t('zhe-kou') }}</div>
              <div class="value">$0.00</div>
            </div>
            <div class="info border">
              <div class="label">{{ $t('lei-ji') }}</div>
              <div class="value">${{ detailData.TotalAmount }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="$t('wu-liu-xin-xi-0')"
      :visible.sync="showLogisticsDialog"
      width="600px"
      class="order-detail-dialog"
    >
      <div class="logistics-list">
        <div class="list" v-for="(item, i) in logisticsList" :key="i">
          <div class="time">{{ item.ExecuteTime }}</div>
          <div class="flex-center-start">
            {{ item.LogisticsRoute.Description }}
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="$t('shu-ru-zhi-fu-mi-ma')"
      :visible.sync="showPopup"
      width="400px"
      class="pay-dialog"
    >
      <div class="pay-box">
        <div class="label">{{ $t('qing-shu-ru-nin-de-zhi-fu-mi-ma') }}</div>
        <passwordKeyboard v-model="password"></passwordKeyboard>
        <div class="btn">
          <el-button
            type="primary"
            :disabled="password.length != 6"
            @click="doPay"
            >{{ $t('que-ren') }}</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import passwordKeyboard from '@/components/password'
import moment from 'moment'
import { orderList, pickupPay, cronList } from '@/api/shop'
export default {
  components: {
    passwordKeyboard
  },
  data() {
    return {
      tabIndex: 0,
      form: {
        orderTime: [],
        orderNo: ''
      },
      tableData: [],
      selectRows: [],
      page: {
        size: 10,
        current: 1,
        total: 1
      },
      password: '',
      showPopup: false,
      payStatusList: [
        {
          value: '',
          label: this.$t('quan-bu')
        },
        {
          value: 1,
          label: this.$t('yi-zhi-fu')
        },
        {
          value: 2,
          label: this.$t('wei-zhi-fu')
        }
      ],
      logisticsStatusList: [
        {
          value: 1,
          label: this.$t('yi-fa-huo')
        },
        {
          value: 2,
          label: this.$t('dai-shou-huo')
        },
        {
          value: 3,
          label: this.$t('yi-qian-shou')
        }
      ],
      detailData: {
        OrderProduct: [],
        ShippingAddress: {}
      },
      logisticsList: [],
      showOrderDialog: false,
      showLogisticsDialog: false
    }
  },
  computed: {
    tabList() {
      return [
        this.$t('quan-bu-ding-dan'),
        this.$t('dai-cai-gou'),
        this.$t('yi-cai-gou')
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let payStatus =
        this.form.payStatus === ''
          ? null
          : this.form.payStatus === 1
          ? [1]
          : [2, 3, 4, 9]
      orderList({
        current: this.page.current,
        pageSize: this.page.size,
        PurchaseStatus: this.tabIndex ? this.tabIndex : null,
        OrderNumber: this.form.orderNo,
        StartDate:
          this.form.orderTime.length > 0
            ? `${moment(this.form.orderTime[0]).format('YYYY-MM-DD')} 00:00:00`
            : null,
        EndDate:
          this.form.orderTime.length > 0
            ? `${moment(this.form.orderTime[1]).format('YYYY-MM-DD')} 23:59:59`
            : null
      }).then((res) => {
        this.tableData = res.data.Items
        this.page.total = res.data.Pagination.totalRecords
      })
    },
    search() {
      this.page.current = 1
      this.page.size = 10
      this.init()
    },
    reset() {
      this.form = {
        orderTime: [],
        orderNo: ''
      }
    },
    changeTab(i) {
      this.tabIndex = i
      this.page.current = 1
      this.init()
    },
    handleSelectionChange(val) {
      this.selectRows = val
    },
    sizeChange(size) {
      this.page.size = size
      this.init()
    },
    currentChange(page) {
      this.page.current = page
      this.init()
    },
    orderDetail(data) {
      this.detailData = data
      this.showOrderDialog = true
    },
    orderLogistics(item) {
      this.showLogisticsDialog = true
      cronList({
        OrderId: item.ID
      }).then((res) => {
        let data = res.data.Items
        if (data.length > 0) {
          let logisticId = this.detailData.ShowRoute
            ? JSON.parse(this.detailData.ShowRoute)
            : []
          data.forEach((v) => {
            if (logisticId instanceof Array) {
              if (logisticId.indexOf(v.LogisticsRouteId) > -1) {
                this.logisticsList.push(v)
              }
            } else {
              if (logisticId[v.LogisticsRouteId]) {
                this.logisticsList.push(v)
              }
            }
          })
        }
      })
    },
    getStatusName(status) {
      let name = ''
      switch (status + '') {
        case '0':
          name = this.$t('dai-fu-kuan')
          break
        case '1':
          name = this.$t('dai-fa-huo')
          break
        case '2':
          name = this.$t('dai-shou-huo')
          break
        case '3':
          name = this.$t('dai-ping-jia')
          break
        case '4':
          name = this.$t('yi-wan-cheng')
          break
        case '5':
          name = this.$t('yi-qu-xiao')
          break
        case '6':
          name = this.$t('yi-guan-bi')
          break
        case '7':
          name = this.$t('tui-huo-zhong')
          break
        case '8':
          name = this.$t('tui-kuan-cheng-gong')
          break
        case '9':
          name = this.$t('yi-ping-jia')
          break
      }
      return name
    },
    batchPay() {
      if (this.selectRows.length === 0) {
        this.$message.warning(this.$t('qing-gou-xuan-shu-ju'))
        return
      }
      this.showPopup = true
    },
    doPay() {
      let index = 0
      this.selectRows.forEach((v) => {
        pickupPay({
          ID: parseFloat(v.ID),
          Password: parseFloat(this.password)
        }).then((res) => {
          if (res.code == 0) {
            this.init()
            this.showPopup = false
            index += 1
            if (index == this.selectRows.length) {
              this.$message.success(this.$t('cao-zuo-cheng-gong'))
            }
          } else {
            this.$message.warning(res.msg)
          }
        })
      })
    }
  }
}
</script>